import http from '@/api/request'

/**
 * 获取验证码
 * @param data
 * @returns {*}
 */
export function getMsg (data) {
  return http().post('/base/api/shop/user/send/verification/code', data)
}

/**
 * 登录后修改密码获取验证码
 * @param data
 * @returns {*}
 */
export function authGetMsg (data) {
  return http().post('/base/auth/shop/user/send/verification/code', data)
}

/**
 * 获取图片验证码
 * @param data
 * @returns {*}
 */
export function getImageVerification (data) {
  return http().get('/base/api/shop/user/get/image/verification', data)
}

/**
 * 用户注册
 * @param data
 * @returns {*}
 */
export function userRegister (data) {
  return http().post('/base/api/shop/user/register', data)
}

/**
 * 密码登录
 * @param data
 * @returns {*}
 */
export function userLoginPwd (data) {
  return http().post('/base/api/shop/user/login/pwd', data)
}

/**
 * 验证码登录
 * @param data
 * @returns {*}
 */
export function userLoginCode (data) {
  return http().post('/base/api/shop/user/login/verification/code', data)
}

/**
 * 忘记密码
 * @param data
 * @returns {*}
 */
export function userResetLoginPwd (data) {
  return http().put('/base/api/shop/user/reset/login/pwd', data)
}

/**
 * 用户信息
 * @param data
 * @returns {*}
 */
export function userView (data) {
  return http().get('/base/auth/shop/user/view', data)
}

/**
 * 用户信息修改
 * @param data
 * @returns {*}
 */
export function userUpdate (data) {
  return http().put('/base/auth/shop/user/update', data)
}

/**
 * 用户头像修改
 * @param data
 * @returns {*}
 */
export function userUpdateHeadImg (data) {
  return http().put('/base/auth/shop/user/update/head/img', data)
}

/**
 * 用户修改登录密码
 * @param data
 * @returns {*}
 */
export function userUpdateLoginPwd (data) {
  return http().put('/base/auth/shop/user/update/login/pwd', data)
}

/**
 * 用户课程分页
 * @param data
 * @returns {*}
 */
export function userCoursePage (data) {
  return http().post('/scm/auth/course/user/page', data)
}

/**
 * 用户课程分页
 * @param data
 * @returns {*}
 */
export function userGradePage (data) {
  return http().post('/resource/auth/classroom/student/page', data)
}

/**
 * 用户课程笔记分页
 * @param data
 * @returns {*}
 */
export function userNotesPage (data) {
  return http().post('/resource/auth/course/notes/user/page', data)
}

/**
 * 用户课程笔记修改
 * @param data
 * @returns {*}
 */
export function userNotesEdit (data) {
  return http().put('/resource/auth/course/notes/edit', data)
}

/**
 * 用户课程笔记取消公开-私密
 * @param data
 * @returns {*}
 */
export function userNotesEditOpenSet (data) {
  return http().put('/resource/auth/course/notes/edit/open/set', data)
}

/**
 * 用户课程笔记删除
 * @param data
 * @returns {*}
 */
export function userNotesDelete (data) {
  return http().delete('/resource/auth/course/notes/delete', data)
}

/**
 * 用户证书分页
 * @param data
 * @returns {*}
 */
export function userCertPage (data) {
  return http().post('/scm/auth/cert/user/page', data)
}

/**
 * 用户证书详情
 * @param data
 * @returns {*}
 */
export function userCertView (id) {
  return http().get(`/scm/auth/cert/user/view?id=${id}`)
}

/**
 * 用户订单分页
 * @param data
 * @returns {*}
 */
export function userOrderPage (data) {
  return http().post('/scm/auth/trade/order/page', data)
}

/**
 * 用户取消订单
 * @param data
 * @returns {*}
 */
export function tradeOrderCancel (data) {
  return http().put('/scm/auth/trade/order/cancel', data)
}

/**
 * 用户订单继续支付
 * @param data
 * @returns {*}
 */
export function tradeOrderContinuePay (data) {
  return http().post('/scm/auth/trade/order/continue/pay', data)
}

/**
 * 交易订单查看状态
 * @param data
 * @returns {*}
 */
export function tradeOrderView (orderNo) {
  return http().get('/scm/auth/trade/order/view?orderNo=' + orderNo)
}

/**
 * 用户收藏分页
 * @param data
 * @returns {*}
 */
export function userCollectionPage (data) {
  return http().post('/scm/auth/collection/user/page', data)
}

/**
 * 用户收藏删除
 * @param data
 * @returns {*}
 */
export function userCollectionDelete (data) {
  return http().delete('/scm/auth/collection/user/delete', { data })
}

/**
 * 用户题目收藏统计
 * @param data
 * @returns {*}
 */
export function userCollectionQuestionCount (data) {
  return http().post('/scm/auth/collection/user/question/count', data)
}

/**
 * 当前用户的学习信息
 * @param params
 * @returns {*}
 */
export function getUserGain (params) {
  return http().get('/resource/auth/course/view/user/gain', { params })
}

/**
 * 用户消息记录分页
 * @param data
 * @returns {*}
 */
export function getMsgUsePage (data) {
  return http().post('/scm/auth/msg/user/page', data)
}

/**
 * 全部标为已读
 * @returns {*}
 */
export function batchUserMsgWatch () {
  return http().put('/scm/auth/msg/user/batch/watch')
}

/**
 * 站内信用户记录查看
 * @returns {*}
 */
export function useMsgView (id) {
  return http().get('/scm/auth/msg/user/view?id=' + id)
}

/**
 * 统计未读消息
 * @returns {*}
 */
export function useMsgCount () {
  return http().get('/scm/auth/msg/user/count')
}

/**
 * 用户消息通知
 * @returns {*}
 */
export function useLastMsgCount (id) {
  return http().get('/scm/auth/msg/user/get/last/msg?id=' + id)
}


/**
 * 站内信批量删除
 * @param params
 * @returns {*}
 */
export function batchUseMsgDelete (data) {
  return http().delete('/scm/auth/msg/user/batch/delete', { data })
}

/**
 * 注销账号
 * @param params
 * @returns {*}
 */
export function userCancel (params) {
  return http().put('/base/auth/shop/user/cancel', {}, { params })
}

/**
 * 验证当前绑定手机号
 * @param params
 * @returns {*}
 */
export function checkPhone (params) {
  return http().put('/base/auth/shop/user/check/phone', {}, { params })
}

/**
 * 绑定新手机
 * @param data
 * @returns {*}
 */
export function updateUserPhone (data) {
  return http().put('/base/auth/shop/user/update/phone', data)
}

/**
 * 用户校验已学习课程播放权限(未购买且不支持单独购买等)
 * @param params
 * @returns {*}
 */
export function courseCheckWatchPermission (data) {
  return http().post('/resource/auth/course/check/watch/permission', data)
}

/**
 * 用户登录后强制阅读协议_获取协议信息接口
 * @param params
 * @returns {*}
 */
export function getUserContract (data) {
  return http().post('/resource/auth/shop/contract/get/user', data)
}

/**
 * 用户登录前强制签约协议_获取协议信息接口
 * @param params
 * @returns {*}
 */
export function checkContractExist (params) {
  return http().post('/resource/auth/shop/contract/check/exist', params)
}

/**
 * 用户登录前强制签约协议_学员信息保存接口
 * @param params
 * @returns {*}
 */
export function contractVariableSave (data) {
  return http().post('/scm/auth/contract/user/variable/save', data)
}

/**
 * 用户登录前强制签约协议_协议下载
 * @param params
 * @returns {*}
 */
export function contractDownload (data) {
  return http().post('/scm/auth/contract/user/variable/download/pdf', data, {responseType: 'blob'})
}


/**
 * 用户直播课表
 * @param params
 * @returns {*}
 */
export function liveSchedule(data) {
  return http().post('/resource/auth/course/live/date/chart', data)
}


/**
 * 用户学业流程图
 * 
 */
export function activePicView() {
  return http().get('/base/auth/shop/active/pic/view')
}

